import logo from './logo.svg';
import './App.css';
import { useEffect } from 'react';


function App() {

  useEffect(() => {
    window.location.href = "https://www.aidash.com/";
  }, []);
  return (
    <div className="App">
      <p>You are being redirected to aidash.com ......</p>
    </div>
  );
}

export default App;
